
import Vue, { PropType } from "vue";

import { Cancel, Process, Snackbar } from "@/interfaces";

import MyLifeline from "@/components/building/MyLifeline.vue";

import http from "@/http";

export interface DataType {
  content: string;
}

export default Vue.extend({
  components: {
    MyLifeline
  },
  props: {
    cancel: {
      type: Object as PropType<Cancel>,
      required: true
    }
  },
  data(): DataType {
    return {
      content: `退去時には、お渡しした鍵をすべてご返却ください。返却がない場合は、鍵交換費用をご請求させていただきます。
コピーキーを作成された方は、合わせてご返却ください。
お渡しした鍵・玄関鍵 本 TR鍵 本`
    };
  },
  computed: {
    href(): string {
      if (this.cancel == null) {
        return "";
      }

      return `${process.env.VUE_APP_API_URL}/cancel/${this.cancel.id}/guidance`;
    }
  },
  mounted() {
    if (this.cancel == null || this.cancel.guidance == null) {
      return;
    }
    this.content = this.cancel.guidance.content;
  },
  methods: {
    async submit() {
      if (window.confirm("本当に送信しますか?") === false) {
        return;
      }

      if (this.cancel == null) {
        return;
      }

      const { id } = this.cancel;

      const url = `/cancels/${id}/guidances`;

      await http.post(url, {
        content: this.content
      });

      const { data } = await http.post<Process[]>(`cancels/${id}/processes`, {
        process_id: 3
      });

      this.cancel.processes = [...data];

      const snackbar: Snackbar = {
        show: true,
        content: "送信しました",
        color: "success"
      };

      this.$store.commit("setSnackbar", snackbar);

      await this.$router.push({
        path: `/cancel/${this.cancel.id}`
      });
    }
  }
});
